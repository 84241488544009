import { t } from 'app/core/internationalization';
import { getBackendSrv } from 'app/core/services/backend_srv';
// import store from 'app/core/store';
import { getDatasourceSrv } from 'app/features/plugins/datasource_srv';

import { SetupStep } from './types';

// const step1TutorialTitle = 'Infoore fundamentals';
// const step2TutorialTitle = 'Create users and teams';
// const keyPrefix = 'getting.started.';
// const step1Key = `${keyPrefix}${step1TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;
// const step2Key = `${keyPrefix}${step2TutorialTitle.replace(' ', '-').trim().toLowerCase()}`;

export const getSteps = (): SetupStep[] => [
  {
    heading: t('welcomeInfoore', ""),
    subheading: t('basicInfo', ""),
    title: t('basic', ""),
    info: t('basicInfo', ""),
    done: false,
    cards: [
      // {
      //   type: 'tutorial',
      //   heading: t('dataSource', ""),
      //   title: t('infooreFundamentals', ""),
      //   info: t('totorialInfo', ""),
      //   href: 'https://grafana.com/tutorials/grafana-fundamentals',
      //   icon: 'grafana',
      //   check: () => Promise.resolve(store.get(step1Key)),
      //   key: step1Key,
      //   done: false,
      // },
      {
        type: 'docs',
        title: t('dataSourcesTitle', ""),
        heading: t('dataSources', ""),
        icon: 'database',
        learnHref: 'https://grafana.com/docs/grafana/latest/features/datasources/add-a-data-source',
        href: 'datasources/new',
        check: () => {
          return new Promise((resolve) => {
            resolve(
              getDatasourceSrv()
                .getMetricSources()
                .filter((item) => {
                  return item.meta.builtIn !== true;
                }).length > 0
            );
          });
        },
        done: false,
      },
      {
        type: 'docs',
        heading: t('dash_boards', ""),
        title: t('createDashboard', ""),
        icon: 'apps',
        href: 'dashboard/new',
        learnHref: 'https://grafana.com/docs/grafana/latest/guides/getting_started/#create-a-dashboard',
        check: async () => {
          const result = await getBackendSrv().search({ limit: 1 });
          return result.length > 0;
        },
        done: false,
      },
    ],
  },
  {
    heading: 'Setup complete!',
    subheading:
      'All necessary steps to use Infoore are done. Now tackle advanced steps or make the best use of this home dashboard – it is, after all, a fully customizable dashboard – and remove this panel.',
    title: t('advanced', ""),
    info: t('advancedTitle', ""),
    done: false,
    cards: [
      // {
      //   type: 'tutorial',
      //   heading: t('teamUsers', ""),
      //   title: t('userTeams', ""),
      //   info: t('userTeamsIcon', ""),
      //   href: 'https://grafana.com/tutorials/create-users-and-teams',
      //   icon: 'users-alt',
      //   key: step2Key,
      //   check: () => Promise.resolve(store.get(step2Key)),
      //   done: false,
      // },
      {
        type: 'docs',
        heading: t('pluginsFind', ""),
        title: t('pluginsTitle', ""),
        learnHref: 'https://grafana.com/docs/grafana/latest/plugins/installation',
        href: 'plugins',
        icon: 'plug',
        check: async () => {
          const plugins = await getBackendSrv().get('/api/plugins', { embedded: 0, core: 0 });
          return Promise.resolve(plugins.length > 0);
        },
        done: false,
      },
    ],
  },
];
